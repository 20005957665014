/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useI18next } from "gatsby-plugin-react-i18next"
import { SnipcartContext } from "../../../plugins/gatsby-plugin-snipcart-advanced-custom/context"
import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"

import useSiteMetadata from "../../queries/useSiteMetadata"
import { PageMetadata } from "../../types/SiteMetadata"

type Props = {
  page?: PageMetadata
}

const SEO: React.FC<Props> = ({ page }) => {
  const site = useSiteMetadata()
  const { language } = useI18next()
  const { changeLanguage } = useContext(SnipcartContext)

  const lang = language || page?.lang || site.lang

  const title = (page?.title && `${page.title} - ${site.title}`) || site.title

  const description = page?.description || site.description

  const url = page?.url || site.siteUrl

  useEffect(() => {
    changeLanguage(lang)
  }, [lang])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title itemProp="name">{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default SEO
