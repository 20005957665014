import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"

export default function SecurityPoliciesPage() {
  return (
    <Layout>
      <SEO
        page={{
          title: "Políticas de Seguridad",
          url: "https://casabi.do/politicas-de-seguridad",
        }}
      />
      <Section>
        <Container>
          <Row>
            <Col sm={10} style={{ margin: `auto` }}>
              <h2>Políticas de Seguridad</h2>

              <h3>Website</h3>
              <p>
                Tomamos todas las medidas y precauciones razonables para proteger tu
                información personal y seguimos las mejores prácticas de la
                industria para asegurar que tu información no sea utilizada de
                manera inapropiada, alterada o destruida.
              </p>

              <p>
                Ciframos la información de tu tarjeta de crédito utilizando la
                tecnología de capa de puerto seguros o Secure Sockets Layer (SSL), y
                la almacenamos con el cifrado AES-256. También, seguimos todos los
                requerimientos del PCI-DSS.
              </p>

              <h3>Pagos</h3>

              <p>
                Los métodos de page utilizados por LA EMPRESA son servidores de
                terceros. Estos servicios de terceros (AZUL), cumplen con todos los
                estándares de seguridad y cifrado para mantener tu información
                segura. Sólo utilizarán la información necesaria para completar el
                proceso requerido. También recomendamos leer las Políticas de
                Privacidad de estos proveedores, para entender mejor cómo maneja la
                información suministrada.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
